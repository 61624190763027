<template>
    <base-layout :public="true" :fill-height="true">
        <v-col class="align-center">
            <v-row justify="center" class="pb-10"><img src="@/assets/TK_logo_dark.svg" class="logo"/></v-row>
            <v-row>
                <v-card class="mx-auto px-6" max-width="600">
                    <v-container fluid pa-10>
                        <v-row justify="center" class="pb-2">
                            <div class="title regalBlue--text">Log in to</div>
                        </v-row>
                        <v-row justify="center">
                            <div class="sub-title regalBlue--text">Market Assessment Tool</div>
                        </v-row>
                        <v-card-text class="pt-5 pb-0">
                            <span class="email-text casper--text">EMAIL</span>
                            <v-text-field
                                v-model="email"
                                :disabled="isLoading"
                                :class="[isEmailHasError ? 'login-text-field-error' : 'login-text-field']"
                                class="pt-0 mt-0 pb-5"
                                background-color="whiteLilac"
                                hide-details
                            ></v-text-field>
                            <span class="password-text casper--text">PASSWORD</span>
                            <v-text-field
                                @click:append="showPassword = !showPassword"
                                v-model="password"
                                :disabled="isLoading"
                                :type="showPassword ? 'text' : 'password'"
                                :class="[isPasswordHasError ? 'login-text-field-error' : 'login-text-field']"
                                class="pt-0 mt-0 pb-8"
                                background-color="whiteLilac"
                                hide-details
                            >
                                <template v-slot:append>
                                    <span @click="showPassword = !showPassword" class="cursor-pointer">
                                        <img v-if="!showPassword" src="@/assets/icons/eye.svg" class="pl-1" />
                                        <img v-else src="@/assets/icons/eye_off.svg" class="pl-1" />
                                    </span>
                                </template>
                            </v-text-field>
                        </v-card-text>
                        <v-row justify="center" class="pb-5">
                            <v-col cols="8">
                                <v-btn @click="onLogin()" :loading="isLoading" color="mangoTango" block dark>
                                    <span class="log-in-text">Log in</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-row>
            <v-row justify="center" class="pt-5">
                <v-btn @click="$router.push({ name: 'forgotPassword' })" :disabled="isLoading" color="paleSky" text>
                    <span class="forgot-password-text">Forgot password?</span>
                </v-btn>
            </v-row>
        </v-col>
    </base-layout>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';

import { REGEX_EMAIL_PATTERN } from '@/core/consts/const';

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            showPassword: false,
            isLoading: false,

            isEmailHasError: false,
            isPasswordHasError: false,
        };
    },
    created() {
        window.addEventListener('keydown', this.onPressKey, false);
    },
    destroyed() {
        window.removeEventListener('keydown', this.onPressKey, false);
    },
    computed: {
        backgroundColor() {
            return this.$vuetify.theme.dark ? this.$vuetify.theme.themes.dark.whiteLilac : this.$vuetify.theme.themes.light.whiteLilac;
        },
    },
    methods: {
        ...mapActions('authenticate', {
            login: 'login',
        }),
        onPressKey(e) {
            e = e || window.event;
            if (e.keyCode === 13) {
                if (!this.isLoading) {
                    this.onLogin();
                }
            }
        },
        async onLogin() {
            this.isEmailHasError = false;
            this.isPasswordHasError = false;
            if (this.email && REGEX_EMAIL_PATTERN.test(this.email.toLowerCase()) && this.password) {
                this.isLoading = true;
                await this.login({
                    email: this.email,
                    password: this.password,
                }).then((res) => {
                    if (res) {
                        this.$router.push({ path: '/' });
                    } else {
                        this.isEmailHasError = true;
                        this.isPasswordHasError = true;
                        this.isLoading = false;
                    }
                });
            } else {
                if (!this.email) {
                    this.isEmailHasError = true;
                    Vue.toasted.error('Email field must not be empty');
                }
                if (this.email && !REGEX_EMAIL_PATTERN.test(this.email.toLowerCase())) {
                    this.isEmailHasError = true;
                    Vue.toasted.error('Email not valid');
                }
                if (!this.password) {
                    this.isPasswordHasError = true;
                    Vue.toasted.error('Password field must not be empty');
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.logo {
    width: 140px;
}

.title {
    font-size: 30px !important;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.114286px !important;
}

.sub-title {
    font-size: 20px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.114286px;
}

.email-text,
.password-text {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 1.21333px;
}

.log-in-text {
    font-size: 15px;
    line-height: 21px;
}

.forgot-password-text {
    font-size: 14px;
    line-height: 17px;
}
</style>
